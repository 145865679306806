import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'
import event_icon from '../images/event_icon.png'
import screen_aktual from '../images/screen_aktual.jpg'

// http://www.dktech.cz/?p=209

const EventScreen: FC = () => {
  return (
    <PageTemplate>
      <PostHeader
        title="Screen event"
        link="/event_screen"
        author="Algy"
        date="18.03.2014"
        time="13:24"
        category="Eventy"
      />
      <p><strong>SCREEN EVENT</strong></p>
      <p>Níže je zveřejněn obrázek ze hry. Úkolem hráčů je v co nejkratším čase najít objekt, který je na obrázku zachycen a přibližné místo, odkud byl snímek pořízen. Odměněni jsou první tři hráči, kteří dorazí na určené místo. Nedaří-li se hráčům objekt nalézet, může GM po určitém čase (cca. 10-15 minut) poslat hráčům nápovědu.<a href={screen_aktual} target="_blank"><img className="size-full wp-image-186 alignright" alt="icon" src={event_icon} width="100" height="75" /></a></p>
      <p>AKTUÁLNÍ EVENT</p>
      <p>Obrázek pro aktuální screen event získáte po kliknutí na ikonku:</p>
      <div className="cleared"></div>
      <span className="linkpages"></span><div className="cleared"></div>

      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default EventScreen

export { Head } from '../components/defaultHtmlHead'